import axios from "axios";
import request from "@/utils/request.js";

export const add_knowledge_base = (params) => axios.post(process.env.VUE_APP_API_CHAT_URL + "api/local_doc_qa/add_knowledge_base", params);
export const get_list_knowledge_base = (params) => axios.post(process.env.VUE_APP_API_CHAT_URL + "api/local_doc_qa/get_list_knowledge_base", params);
export const edit_knowledge_base = (params) => axios.post(process.env.VUE_APP_API_CHAT_URL + "api/local_doc_qa/edit_knowledge_base", params);
export const del_knowledge_base = (params) => axios.post(process.env.VUE_APP_API_CHAT_URL + "api/local_doc_qa/del_knowledge_base", params);
export const upload_file = (params) => axios.post(process.env.VUE_APP_API_CHAT_URL + "api/local_doc_qa/upload_file", params);
export const upload_weblinks = (params) => axios.post(process.env.VUE_APP_API_CHAT_URL + "api/local_doc_qa/upload_weblinks", params);
export const get_list_files = (params) => axios.post(process.env.VUE_APP_API_CHAT_URL + "api/local_doc_qa/get_list_files", params);
export const del_files = (params) => axios.post(process.env.VUE_APP_API_CHAT_URL + "api/local_doc_qa/del_files", params);
export const local_doc_chats = (params) => axios.post(process.env.VUE_APP_API_CHAT_URL + "api/local_doc_qa/local_doc_chats", params);
export const get_Knowledge = (params) => request.post("/api/Knowledge/get_Knowledge", params);
export const set_Knowledge = (params) => request.post("/api/Knowledge/set_Knowledge", params);
